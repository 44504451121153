<template>
  <div class="dashboard-page">
    <el-card class="top-info">
      <div class="title">{{ $t('yi-biao-pan') }}</div>
      <div class="list">
        <div class="box">
          <div class="count">{{ detailData.ProductCount || 0 }}</div>
          <div class="label">{{ $t('shang-pin-zong-shu') }}</div>
        </div>
        <div class="box">
          <div class="count">
            ${{
              detailData.TotalSales ? detailData.TotalSales.toFixed(2) : '0.00'
            }}
          </div>
          <div class="label">{{ $t('xiao-shou-zong-e') }}</div>
        </div>
        <div class="box">
          <div class="count">{{ detailData.OrderCount || 0 }}</div>
          <div class="label">{{ $t('zong-ding-dan') }}</div>
        </div>
        <div class="box">
          <div class="count">
            ${{
              detailData.TotalProfit
                ? detailData.TotalProfit.toFixed(2)
                : '0.00'
            }}
          </div>
          <div class="label">{{ $t('zong-li-run') }}</div>
        </div>
      </div>
    </el-card>

    <div class="overview">
      <el-card>
        <div class="title">{{ $t('dian-pu-gai-kuang') }}</div>
        <div class="list">
          <div class="info">
            <div class="count">{{ detailData.CompositeScore || 0 }}</div>
            <div class="label">{{ $t('zong-he-ping-fen') }}</div>
          </div>
          <div class="info">
            <div class="count">{{ detailData.Credit || 0 }}</div>
            <div class="label">{{ $t('mai-jia-xin-yong-fen') }}</div>
          </div>
          <div class="info">
            <div class="count">{{ detailData.ShopFollow || 0 }}</div>
            <div class="label">{{ $t('dian-pu-guan-zhu') }}</div>
          </div>
        </div>
      </el-card>
      <el-card>
        <div class="title">{{ $t('liu-liang-gai-kuang') }}</div>
        <div class="list">
          <div class="info">
            <div class="count">{{ visitCountObj.today }}</div>
            <div class="label">{{ $t('jin-ri-fang-ke-shu') }}</div>
          </div>
          <div class="info">
            <div class="count">{{ visitCountObj.last7 }}</div>
            <div class="label">{{ $t('7-ri-fang-ke-shu') }}</div>
          </div>
          <div class="info">
            <div class="count">{{ visitCountObj.last30 }}</div>
            <div class="label">{{ $t('30-ri-fang-ke-shu') }}</div>
          </div>
        </div>
      </el-card>
      <el-card>
        <div class="title">{{ $t('jin-ri-gai-kuang') }}</div>
        <div class="list">
          <div class="info">
            <div class="count">{{ detailData.DayOrder || 0 }}</div>
            <div class="label">{{ $t('jin-ri-ding-dan') }}</div>
          </div>
          <div class="info">
            <div class="count">
              ${{
                detailData.DaySales ? detailData.DaySales.toFixed(2) : '0.00'
              }}
            </div>
            <div class="label">{{ $t('jin-ri-xiao-shou-e') }}</div>
          </div>
          <div class="info">
            <div class="count">
              ${{
                detailData.DayProfit ? detailData.DayProfit.toFixed(2) : '0.00'
              }}
            </div>
            <div class="label">{{ $t('yu-ji-li-run') }}</div>
          </div>
        </div>
      </el-card>
    </div>

    <el-card class="sale-statistics">
      <div class="time-tab">
        <!-- <el-button
          size="mini"
          class="tab"
          plain
          :type="timeFlag == 1 ? 'primary' : ''"
          @click="changeTime(1)"
          >今日</el-button
        > -->
        <el-button
          size="mini"
          class="tab"
          plain
          :type="timeFlag == 2 ? 'primary' : ''"
          @click="changeTime(2)"
          >{{ $t('jin-7-tian') }}</el-button
        >
        <el-button
          size="mini"
          class="tab"
          plain
          :type="timeFlag == 3 ? 'primary' : ''"
          @click="changeTime(3)"
          >{{ $t('jin-30-tian') }}</el-button
        >
      </div>
      <div class="echart-box" id="lineEcharts"></div>
    </el-card>

    <div class="sub-title">
      {{ $t('nin-de-lei-bie') }}
      ({{ categoryList.length }})
    </div>

    <div class="category-list">
      <Swipe
        ref="swipe"
        :loop="false"
        :show-indicators="false"
        :width="180"
        :height="188"
      >
        <SwipeItem
          v-for="(item, i) in categoryList"
          :key="i"
          @click="toCategory(item)"
        >
          <div class="category flex-center">
            <img :src="categoryUrl" alt="" />
            <div class="count">
              <span>{{
                item.ProductCategoryTranslations
                  ? item.ProductCategoryTranslations[0].Name
                  : ''
              }}</span>
              <span>({{ item.ProductCount }})</span>
            </div>
          </div>
        </SwipeItem>
      </Swipe>
    </div>

    <div class="bottom-info">
      <el-card class="left-card">
        <div class="title">{{ $t('re-xiao-shang-pin-top10') }}</div>
        <el-table :data="topTableData" :stripe="true" size="small">
          <el-table-column type="index" width="80px"></el-table-column>
          <el-table-column :label="$t('shang-pin-ming-cheng')" prop="name">
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.ProductTranslation
                    ? scope.row.ProductTranslation[0].Name
                    : ''
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('jia-ge')"
            prop="SalePrice"
            width="100px"
            align="center"
          ></el-table-column>
          <el-table-column
            :label="$t('xiao-liang-0')"
            prop="Sales"
            width="100px"
            align="center"
          ></el-table-column>
        </el-table>
      </el-card>
      <div class="right">
        <el-card class="order-statistics">
          <div class="title">{{ $t('ding-dan-tong-ji') }}</div>
          <div class="list">
            <div class="info">
              <div class="count">{{ detailData.OrderCount || 0 }}</div>
              <div class="label">{{ $t('zong-ding-dan-0') }}</div>
            </div>
            <div class="info">
              <div class="count">{{ detailData.Delivery || 0 }}</div>
              <div class="label">{{ $t('jin-hang-zhong') }}</div>
            </div>
            <div class="info">
              <div class="count">{{ detailData.ConfirmReceipt || 0 }}</div>
              <div class="label">{{ $t('yi-wan-cheng') }}</div>
            </div>
            <div class="info">
              <div class="count">{{ detailData.CancelOrder || 0 }}</div>
              <div class="label">{{ $t('qu-xiao-ding-dan') }}</div>
            </div>
            <div class="info">
              <div class="count">{{ detailData.Refund || 0 }}</div>
              <div class="label">{{ $t('yi-tui-kuan') }}</div>
            </div>
          </div>
        </el-card>
        <el-card class="verify-card">
          <div class="img-box" v-if="isShopAuth">
            <img :src="verifyUrl" alt="" />
          </div>
          <div class="title" v-else>{{ $t('dian-pu-she-zhi') }}</div>
          <div class="info" v-if="!isShopAuth">
            <div class="label">
              {{ $t('guan-li-he-she-zhi-nin-de-dian-pu') }}
            </div>
            <el-button type="primary" size="small" @click="toSetting">{{
              $t('li-ji-she-zhi')
            }}</el-button>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import { shopProductList } from '@/api/shop'
import { SwipeItem, Swipe } from 'vant'
import {
  indexInfo,
  orderStatistics,
  visitLog,
  getShopCategoryList
} from '@/api/shop'
import * as echarts from 'echarts'
import moment from 'moment'
export default {
  name: 'about',
  components: {
    SwipeItem,
    Swipe
  },
  data() {
    return {
      timeFlag: 2,
      activeIndex: 0,
      stepIndex: 0,
      categoryUrl: require('@/assets/imgs/category1.png'),
      verifyUrl: require('@/assets/imgs/verify.png'),
      topTableData: [],
      lineChart: null,
      detailData: {},
      orderDataList: [],
      logDataList: [],
      xData: [],
      isOrderInit: false,
      isLogInit: false,
      visitCountObj: {
        today: 0,
        last7: 0,
        last30: 0
      },
      categoryList: []
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    isShopAuth() {
      return this.$store.state.isShopAuth
    },
    shopId() {
      return this.$store.state.shopId
    },
    lang() {
      return this.$store.state.lang
    }
  },
  watch: {
    shopId(val) {
      if (val) {
        this.initGoods()
      }
    }
  },
  mounted() {
    this.init()
    this.initOrder()
    this.initVisit()
    if (this.shopId) {
      this.initGoods()
    }
    this.initCategory()
  },
  methods: {
    initCategory() {
      getShopCategoryList().then((res) => {
        this.categoryList = res.data
      })
    },
    initOrder() {
      orderStatistics({
        current: 1,
        pageSize: 30
      }).then((res) => {
        this.orderDataList = res.data || []
        this.isOrderInit = true
        if (this.isLogInit) {
          this.initEchart()
        }
      })
    },
    initVisit() {
      visitLog({
        current: 1,
        pageSize: 30
      }).then((res) => {
        this.logDataList = res.data.Items
        this.isLogInit = true
        if (this.isOrderInit) {
          this.initEchart()
        }

        // 访问量统计
        this.getVisitCount()
      })
    },
    getVisitCount() {
      let sum = 0
      for (let i = 0; i < 30; i++) {
        let date = moment().subtract('days', i).format('YYYY-MM-DD')
        let item = this.logDataList.find((v) => {
          return v.Date == date
        })
        let count = item ? item.Visit : 0
        sum += count
        if (i == 0) {
          this.visitCountObj.today = sum
        } else if (i == 6) {
          this.visitCountObj.last7 = sum
        } else if (i == 29) {
          this.visitCountObj.last7 = sum
        }
      }
    },
    init() {
      indexInfo().then((res) => {
        this.detailData = res.data
      })

      //类别
      this.$store.dispatch('initCategory', this.lang)
    },
    initGoods() {
      shopProductList({
        current: 1,
        pageSize: 10,
        ShopID: this.shopId,
        Sort: 'sales'
      }).then((res) => {
        this.topTableData = res.data.Items
      })
    },
    toSetting() {
      this.$router.push({
        name: 'shopSetting'
      })
    },
    changeTime(type) {
      this.timeFlag = type
      this.initEchart()
    },
    getXData(type) {
      let size = type == 2 ? 7 : 30
      let list = []
      for (let i = 0; i < size; i++) {
        let date = moment().subtract('days', i).format('YYYY-MM-DD')
        list.push(date)
      }
      list.reverse()
      return list
    },
    getOrderData() {
      let list = []
      this.xData.forEach((v) => {
        let item = this.orderDataList.find((val) => {
          return val.Date == v
        })
        let val = item ? item.OrderNum : 0
        list.push(val)
      })
      return list
    },
    getLogData() {
      let list = []
      this.xData.forEach((v) => {
        let item = this.logDataList.find((val) => {
          return val.Date == v
        })
        let val = item ? item.Visit : 0
        list.push(val)
      })
      return list
    },
    initEchart() {
      let dom = document.getElementById('lineEcharts')
      this.lineChart = echarts.init(dom)
      let xData = this.getXData(this.timeFlag)
      this.xData = xData
      let data2 = this.getOrderData()
      let data = this.getLogData()
      const option = {
        grid: {
          // 控制chart的上下左右间距
          left: 20,
          right: 20,
          bottom: 10,
          top: 50,
          containLabel: true
        },
        legend: {
          data: [this.$t('xiao-liang-1'), this.$t('liu-lan')],
          left: 0
        },
        tooltip: {
          show: true,
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: xData,
          axisTick: {
            // 刻度
            show: false
          },
          axisLine: {
            // 设置轴线的颜色
            lineStyle: {
              color: '#71c3ff'
            }
          },
          axisLabel: {
            // X轴文字
            show: true,
            textStyle: {
              color: '#71c3ff',
              fontSize: 12,
              fontWeight: '400'
            },
            padding: [0, 0, 0, 0] // 设置 padding 值为 [10, 0, 0, 0]
          }
        },
        yAxis: {
          type: 'value',
          axisTick: {
            // 刻度
            show: false
          },
          axisLine: {
            // 设置轴线的颜色
            show: true,
            lineStyle: {
              color: '#71c3ff'
            }
          },
          axisLabel: {
            // y轴文字
            show: true,
            textStyle: {
              color: '#71c3ff',
              fontSize: 12,
              fontWeight: '400',
              fontFamily: 'Source Han Sans CN-Regular'
            },
            padding: [0, 0, 0, 0] // 设置 padding 值为 [10, 0, 0, 0]
          }
        },
        series: [
          {
            name: this.$t('liu-lan-0'),
            data: data,
            type: 'line',
            color: '#71c3ff'
          },
          {
            name: this.$t('xiao-liang-2'),
            data: data2,
            type: 'line',
            color: '#FC019D'
          }
        ]
      }
      this.lineChart.setOption(option)
    },
    toCategory(data) {
      this.$router.push({
        name: 'shopProducts',
        query: {
          category: data.ID
        }
      })
    }
  }
}
</script>
<style lang="less">
</style>
